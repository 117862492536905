exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-the-show-js": () => import("./../../../src/pages/about-the-show.js" /* webpackChunkName: "component---src-pages-about-the-show-js" */),
  "component---src-pages-about-the-theatre-js": () => import("./../../../src/pages/about-the-theatre.js" /* webpackChunkName: "component---src-pages-about-the-theatre-js" */),
  "component---src-pages-acceptable-use-policy-js": () => import("./../../../src/pages/acceptable-use-policy.js" /* webpackChunkName: "component---src-pages-acceptable-use-policy-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-book-tickets-js": () => import("./../../../src/pages/book-tickets.js" /* webpackChunkName: "component---src-pages-book-tickets-js" */),
  "component---src-pages-cast-and-creative-js": () => import("./../../../src/pages/cast-and-creative.js" /* webpackChunkName: "component---src-pages-cast-and-creative-js" */),
  "component---src-pages-competition-js": () => import("./../../../src/pages/competition.js" /* webpackChunkName: "component---src-pages-competition-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-find-us-js": () => import("./../../../src/pages/find-us.js" /* webpackChunkName: "component---src-pages-find-us-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-and-reviews-js": () => import("./../../../src/pages/news-and-reviews.js" /* webpackChunkName: "component---src-pages-news-and-reviews-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-royal-box-booking-js": () => import("./../../../src/pages/royal-box-booking.js" /* webpackChunkName: "component---src-pages-royal-box-booking-js" */),
  "component---src-pages-terms-and-conditions-for-purchase-of-tickets-js": () => import("./../../../src/pages/terms-and-conditions-for-purchase-of-tickets.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-for-purchase-of-tickets-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-ticket-information-js": () => import("./../../../src/pages/ticket-information.js" /* webpackChunkName: "component---src-pages-ticket-information-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-website-terms-and-conditions-of-use-js": () => import("./../../../src/pages/website-terms-and-conditions-of-use.js" /* webpackChunkName: "component---src-pages-website-terms-and-conditions-of-use-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

